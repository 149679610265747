export default {
  title: "Services",
  projects: {
    web_applications: {
      title: "Web Applications",
      content: [
        "Internet portals and websites are the cornerstones of every respected business. They tend to create and engage a community around a given topic. From simple informational websites, through exceptionally designed user services, to fully customizable heavy data loaded portals, we deliver solutions that provide you precisely what you need to stay ahead of the competition. By analyzing user journey and working closely with our graphics, we know how to develop a space that allows you to interact with the user through individual content settings.",
      ],
      bullet_points: [
        "Python Django Development",
        "ASP.NET Development",
        "React.JS",
        "Responsive Web Design (RWD)",
        "Single Page Application (SPA)",
        "Responsive Web Design (RWD)",
      ],
    },
    mobile_applications: {
      title: "Mobile Applications",
      content: [
        "Currently, over 50% of internet traffic is generated by mobile. Mobile apps are becoming a must-have instead of a nice-to-have for most businesses. When we develop one, we don’t just build a mobile app. We translate your idea to a fully working, robust, and user friendly product. Your targeted audience can be engaged only when the applications are developed in accordance with the way your business operates and are tailored to your specific requirements.",
      ],
      bullet_points: [
        "React Native",
        "Progressive Web Application (PWA)",
        "iOS Development",
        "Android Development",
        "Unity Engine",
        "Apache Cordova Development",
      ],
    },
    desktop_applications: {
      title: "Desktop Applications",
      content: [
        "Have you thought about merging all of your business applications into one? Do you want to create a powerful platform that will optimize your work processes? Or maybe you want to build from scratch a custom software that has never been done before? No matter how you want to tackle the problem, we can provide you a highly innovative, secure, and accessible solution with the highest SLA, maintenance, and support.",
      ],
      bullet_points: [
        "Windows Applications",
        "Windows Forms",
        "Windows Presentation Foundation (WPF)",
        "Cross-platform desktop applications",
        "Universal Windows Platform (UWP)",
        "Windows Service",
      ],
    },
    data_engineering: {
      title: "Data Engineering",
      content: [
        "21 st. Century is, with no doubt, the century of data. At the beginning of 2020, the number of bytes in the digital universe was 40 times bigger than the number of stars in the observable universe.",
        "That is a lot, and there is a need to manage it and analyze it.",
        "And that is exactly what we do. We have thorough experience in collecting, storing, processing, and analysis of data. We turn vast amounts of raw data into useful and meaningful information and address our clients' problems. ",
      ],
      bullet_points: ["Web scraping", "Data mining", "Natural Language Processing", "Machine Learning"],
    },
    game_development: {
      title: "Game Development",
      content: [
        "Do you want to develop a game for educational purposes? Or maybe you have an innovative idea and want to monetize it? With the Unity3D framework, we can create mobile and desktop games from scratch. Being gamers ourselves for many years helps us understand the importance of game contents and details. Original graphics, realistic animations, smooth user interface, and most importantly, gameplay are what we deliver to our clients.",
      ],
      bullet_points: ["Unity3D", "Game design", "Mobile games", "3D games", "2D Games", "Desktop games"],
    },
    team_extension: {
      title: "Team Extension",
      content: [
        "You have your in-house team, and you think about optimizing your costs while scaling? We are always ready to delegate a dedicated development team at your disposal. Our team supports your internal resources remotely, so you can increase your project velocity and achieve remarkable results without worrying about your budget and the whole recruitment process.",
      ],
      bullet_points: [
        "Software Development Outsourcing",
        "Cost & Time Saving",
        "Dedicated Remote Agile Teams",
        "Managed Services",
        "End To End Development",
        "Create & Expand Your Digital Product",
      ],
    },
  },
  benefits_title: "Key Benefits",
  benefits: [
    {
      title: "Agile methodology",
      description:
        "Our agile approach is based on the sprints method. It allows us to develop the project in a more efficient way getting measurable feedback as soon as the new functionalities are in the testing phase.",
    },
    {
      title: "Transparency and communication",
      description:
        "We believe that transparency and communication are the key factors to build successful business relations. Our clients have permanent access to the JMR Slack channel to keep in touch with all team members.",
    },
    {
      title: "Your idea protection",
      description:
        "We understand you may want to protect your idea. We are ready to sign the NDA so that you can be sure that your intellectual property is safe.",
    },
    {
      title: "Time and materials contract",
      description:
        "Your billing is based on the total worked hours' number of the project team. You have instant access to our work log, so you can see the amount of time we spent working on your project at the end of each day. You know exactly what you pay for.",
    },
  ],
}
