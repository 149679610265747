import React, { useEffect, useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import Markdown from "marked-react"
import styled from "styled-components"

import DescriptionRow from "components/common/DescriptionRow"
import Text from "components/common/Text"
import urls from "config/urls"
import themeObj from "styles/theme"

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledList = styled.ul`
  list-style: inside;
`

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.color.graphite};
`

const query = graphql`
  query ServicesQuery {
    allStrapiServices(sort: { fields: [order], order: ASC }) {
      nodes {
        name
        slug
        id
        description
        order
        technologies {
          technology
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

const CONTENT_CLASSNAME = "service_content_element"

const OurServicesList = () => {
  const { allStrapiServices } = useStaticQuery(query)
  const [contentHeights, setContentHeights] = useState({})
  const [expanded, setExpanded] = useState(null)

  const onExpand = id => () => setExpanded(prevId => (prevId === id ? null : id))

  const calculateContentHeights = () => {
    const contentWrapper = [...document.querySelectorAll(`.${CONTENT_CLASSNAME}`)]
    setContentHeights(
      contentWrapper.reduce((accumulator, currentElement) => {
        const key = currentElement.dataset.id
        const { height } = currentElement.getBoundingClientRect()
        accumulator[key] = height
        return accumulator
      }, {})
    )
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", calculateContentHeights)
      setTimeout(calculateContentHeights, 0)
      return () => window.removeEventListener("resize", calculateContentHeights)
    }
  }, [])

  return (
    <StyledWrapper>
      {allStrapiServices.nodes.map(({ id, slug, name, description, image, technologies }, index) => (
        <DescriptionRow
          key={id}
          background={themeObj.color.graphite}
          color={index % 2 ? "cyan" : "white"}
          contentClassName={CONTENT_CLASSNAME}
          finalHeight={contentHeights[id]}
          id={id}
          image={image && image.localFile}
          isEven={index % 2}
          isExpanded={id === expanded}
          onExpand={onExpand(id)}
          readMoreUrl={`${process.env.GATSBY_ROOT_URL}${urls.servicesElement.url}${slug}`}
          title={name}
        >
          <StyledContentWrapper>
            <Text inverted margin="0 0 2rem">
              <Markdown>{description}</Markdown>
            </Text>
            <StyledList>
              {technologies.map(({ technology }) => (
                <Text element="li" inverted uppercase>
                  {technology}
                </Text>
              ))}
            </StyledList>
          </StyledContentWrapper>
        </DescriptionRow>
      ))}
    </StyledWrapper>
  )
}

export default OurServicesList
