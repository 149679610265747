import React from "react"

import PropTypes from "prop-types"

import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import TrustUs from "components/common/TrustUs"
// import KeyBenefits from "components/pages/services/KeyBenefits"
import OurServicesList from "components/pages/services/OurServicesList"
import services from "content/services"

const Services = ({ location }) => (
  <TransitionWrapper location={location}>
    <Seo seoPageName="Services" />
    <SubpageHeader subpageTitle={services.title} />
    <OurServicesList />
    {/* <KeyBenefits /> */}
    <TrustUs />
  </TransitionWrapper>
)

Services.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Services
